import * as THREE from 'three';
import {
	computeBoundsTree,
	disposeBoundsTree,
	acceleratedRaycast,
	MeshBVHVisualizer
} from 'three-mesh-bvh';
import Stats from 'three/addons/libs/stats.module.js';
import {
	Rhino3dmLoader
} from 'three/addons/loaders/3DMLoader.js';
import {
	OrbitControls
} from 'three/addons/controls/OrbitControls.js';
import {
	GUI
} from 'three/addons/libs/lil-gui.module.min.js';
import {
	STLExporter
} from 'three/addons/exporters/STLExporter.js';

// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

let stats;
let camera, scene, renderer;
let mesh, helper, bvh;
let sphereInstance, lineSegments, lightSphere, pointLight1, pointLight2, pointLight3, pointLight4;

// reusable variables
const clock = new THREE.Clock();
const _raycaster = new THREE.Raycaster();
const _position = new THREE.Vector3();
const _quaternion = new THREE.Quaternion();
const _scale = new THREE.Vector3(1, 1, 1);
const _matrix = new THREE.Matrix4();
const _axis = new THREE.Vector3();
const MAX_RAYS = 3000;
const RAY_COLOR = 0xAAAAAA;

/**
 *Textures
**/
const textureLoader = new THREE.TextureLoader()

// matcapTextures
const matcapTexture1 = textureLoader.load('/textures/matcaps/1.png');
const matcapTexture2 = textureLoader.load('/textures/matcaps/2.png');
const matcapTexture3 = textureLoader.load('/textures/matcaps/3.png');
const matcapTexture4 = textureLoader.load('/textures/matcaps/4.png');
const matcapTexture5 = textureLoader.load('/textures/matcaps/5.png');
const matcapTexture6 = textureLoader.load('/textures/matcaps/6.png');
const matcapTexture7 = textureLoader.load('/textures/matcaps/7.png');
const matcapTexture8 = textureLoader.load('/textures/matcaps/8.png');

// Materials
let material0 = new THREE.MeshPhongMaterial({

	color: '#7aa19c',
	flatShading: false,
	shininess: 1.0

});

const material1 = new THREE.MeshMatcapMaterial({

	matcap: matcapTexture1

});

const material2 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture2

});

const material3 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture3

});

const material4 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture4

});

const material5 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture5

});

const material6 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture6

});

const material7 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture7

});

const material8 = new THREE.MeshMatcapMaterial({

    matcap: matcapTexture8

});

let params = {

	count: 150,
	firstHitOnly: true,
	useBVH: true,

	displayHelper: false,
	helperDepth: 10,

	pointLightVisible: false,
	pointLightIntensity: 1,
	pointLight1Color: '#7aa19c',
	pointLight2Color: '#7aa19c',
	pointLight3Color: '#7aa19c',
	pointLight4Color: '#7aa19c',

	visible: true,
	material: material0,
	wireframe: false,
	transparent: false,
	opacity: 0.90,

	meshRotationX: 0.0003,
	meshRotationY: 0.0003,
	meshRotationZ: 0.0003

};

console.time('Initialization Time:');
init();
console.timeEnd('Initialization Time:');
animate();

function init() {

	// environment
	camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 1, 100);
	camera.position.x = 0;
	camera.position.y = 0;
	camera.position.z = 10;

	scene = new THREE.Scene();
	scene.background = new THREE.Color(0x090909);

	// renderer
	renderer = new THREE.WebGLRenderer({
		antialias: true
	});
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(window.innerWidth, window.innerHeight);
	document.body.appendChild(renderer.domElement);

	stats = new Stats();
	document.body.appendChild(stats.dom);

	// raycast visualizations
	const lineGeometry = new THREE.BufferGeometry();
	lineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(MAX_RAYS * 2 * 3), 3));
	lineSegments = new THREE.LineSegments(lineGeometry, new THREE.LineBasicMaterial({

		color: RAY_COLOR,
		transparent: true,
		opacity: 0.25,
		depthWrite: true,
		// forceSinglePass: true

	}));

	sphereInstance = new THREE.InstancedMesh(
		new THREE.SphereGeometry(),
		new THREE.MeshBasicMaterial({

			color: RAY_COLOR,
			// forceSinglePass: true

		}),
		2 * MAX_RAYS
	);

	sphereInstance.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
	sphereInstance.count = 0;

	// PointLights
	const lightSphere = new THREE.SphereGeometry( .1, 32, 32 );

	pointLight1 = new THREE.PointLight( params.pointLight1Color, params.pointLightIntensity, 10)
	pointLight1.add(new THREE.Mesh(lightSphere, new THREE.MeshBasicMaterial({color: params.pointLight1Color})));
	pointLight1.children[0].visible = params.pointLightVisible;
	pointLight1.castShadow = true;

	pointLight2 = new THREE.PointLight( params.pointLight2Color, params.pointLightIntensity, 10)
	pointLight2.add(new THREE.Mesh(lightSphere, new THREE.MeshBasicMaterial({color: params.pointLight2Color})));
	pointLight2.children[0].visible = params.pointLightVisible;
	pointLight2.castShadow = true;

	pointLight3 = new THREE.PointLight( params.pointLight3Color, params.pointLightIntensity, 10)
	pointLight3.add(new THREE.Mesh(lightSphere, new THREE.MeshBasicMaterial({color: params.pointLight3Color})));
	pointLight3.children[0].visible = params.pointLightVisible;
	pointLight3.castShadow = true;

	pointLight4 = new THREE.PointLight( params.pointLight4Color, params.pointLightIntensity, 10)
	pointLight4.add(new THREE.Mesh(lightSphere, new THREE.MeshBasicMaterial({color: params.pointLight4Color})));
	pointLight4.children[0].visible = params.pointLightVisible;
	pointLight4.castShadow = true;

	scene.add(sphereInstance, lineSegments, pointLight1, pointLight2, pointLight3, pointLight4);

	// load the 3DM file
	const rhinoLoader = new Rhino3dmLoader();
	rhinoLoader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/');
	rhinoLoader.load('models/3dm/NeilPandyaGeneratedSurface.3dm', object => {

			mesh = object.children[0];

			const geometry = mesh.geometry;
			geometry.center();
			geometry.rotateX(-1.5);
			geometry.translate(0, 1.75, 0);
			geometry.computeBoundsTree();

			mesh.scale.setScalar(0.30);
			mesh.material.forceSinglePass = true;
			mesh.material = params.material;
			mesh.material.visible = params.visible;
			mesh.material.wireframe = params.wireframe;
			mesh.material.transparent = params.transparent;
			mesh.material.opacity = params.opacity;
			mesh.material.reflectivity = params.reflectivity;
			mesh.material.specular = material0.specular;
			mesh.material.shininess =  material0.shininess;
			mesh.material.flatshading = material0.shininess;

			bvh = geometry.boundsTree;

			if (!params.useBVH) {

				geometry.boundsTree = null;

			};

			scene.add(mesh);

			helper = new MeshBVHVisualizer(mesh);
			helper.color.set('#aaaaaa');
			scene.add(helper);

		},

		// called as loading progresses
		function(xhr) {
			console.log((xhr.loaded / xhr.total * 100) + '% loaded');
		},

		// called when loading has errors
		function(error) {
			console.log('An error happened');
		}

	);

	const controls = new OrbitControls(camera, renderer.domElement);
	controls.enableDamping = true;
    controls.enablePan = true;
    controls.dampingFactor = .05;
	controls.minDistance = 5;
	controls.maxDistance = 75;

	const stopRotation = {

		stopMeshRotation: function() {

		params.meshRotationX = 0;
		params.meshRotationY = 0;
		params.meshRotationZ = 0;

		}

	};

	// GUI
	const gui = new GUI().close();

	const rayFolder = gui.addFolder('Raycasting').close();
	rayFolder.add(params, 'count', 1, MAX_RAYS, 1);

	const helperFolder = gui.addFolder('BVH Helper').close();
	helperFolder.add(params, 'displayHelper');
	helperFolder.add(params, 'helperDepth', 1, 20, 1).onChange(v => {

		helper.depth = v;
		helper.update();

	});

	const pointLightFolder = gui.addFolder('Point Lights').close();
	pointLightFolder.add(params, 'pointLightVisible')
	.name('Show')
	.onChange( visible => {

		pointLight1.children[0].visible = params.pointLightVisible;
		pointLight2.children[0].visible = params.pointLightVisible;
		pointLight3.children[0].visible = params.pointLightVisible;
		pointLight4.children[0].visible = params.pointLightVisible;

	});
	pointLightFolder.add(params, 'pointLightIntensity', 0, 100, .001)
	.name('Intensity')
	.onChange( init => {

		pointLight1.intenstiy = params.pointLightIntensity;
		pointLight2.intenstiy = params.pointLightIntensity;
		pointLight3.intenstiy = params.pointLightIntensity;
		pointLight4.intenstiy = params.pointLightIntensity;

	});
	pointLightFolder.addColor(params, 'pointLight1Color')
	.name('PointLight1 Color')
	.onChange( color => {

		pointLight1.children[0].material.needsUpdate = true;
		pointLight1.children[0].material.color.set(params.pointLight1Color);
		pointLight1.color.set(params.pointLight1Color);

	});
	pointLightFolder.addColor(params, 'pointLight2Color')
	.name('PointLight2 Color')
	.onChange( color => {

		pointLight2.children[0].material.needsUpdate = true;
		pointLight2.children[0].material.color.set(params.pointLight2Color);
		pointLight2.color.set(params.pointLight2Color);

	});
	pointLightFolder.addColor(params, 'pointLight3Color')
	.name('PointLight3 Color')
	.onChange( color => {

		pointLight3.children[0].material.needsUpdate = true;
		pointLight3.children[0].material.color.set(params.pointLight3Color);
		pointLight3.color.set(params.pointLight3Color);

	});
	pointLightFolder.addColor(params, 'pointLight4Color')
	.name('PointLight4 Color')
	.onChange( color => {

		pointLight4.children[0].material.needsUpdate = true;
		pointLight4.children[0].material.color.set(params.pointLight4Color);
		pointLight4.color.set(params.pointLight4Color);

	});

	const materialFolder = gui.addFolder('Mesh Material').close();
	materialFolder.add(params, 'material', {

		material0,
		material1,
		material2,
		material3,
		material4,
		material5,
		material6,
		material7,
		material8

	})
	.name('Select Material')
	.onChange( material => {

		mesh.material.needsUpdate = true;
		mesh.material = params.material;

	});
	materialFolder.add(params, 'visible')
	.name('Show')
	.onChange( material => {

		mesh.material.needsUpdate = true;
		mesh.material.visible = params.visible;

	});
	materialFolder.add(params, 'wireframe')
	.name('Show Wireframe')
	.onChange( material => {

		mesh.material.needsUpdate = true;
		mesh.material.wireframe = params.wireframe;

	});
	materialFolder.add(params, 'transparent')
	.name('Transparent')
	.onChange( material => {

		mesh.material.needsUpdate = true;
		mesh.material.transparent = params.transparent;

	});
	materialFolder.add(params, 'opacity', 0, 1, .01)
	.name('Opacity')
	.onChange( material => {

		mesh.material.needsUpdate = true;
		mesh.material.opacity = params.opacity;

	});
	materialFolder.add(material0, 'flatShading')
	.name('material0 Flat Shading')
	.onChange( material => {

		material0.needsUpdate = true;

	});
	materialFolder.add(material0, 'shininess', 0, 1000, .01)
	.name('material0 Shininess')
	.onChange( material => {

		material0.needsUpdate = true;

	});
	materialFolder.addColor(material0, 'color')
	.name('material0 Color')
	.onChange( material => {

		material0.needsUpdate = true;

	});

	/* STL Exporter */
	// Configure export options
	const exporter = new STLExporter();
	const options = { binary: true }
	const exportParams = {

		exportASCII: exportASCII,
		exportBinary: exportBinary

	};

	const motionFolder = gui.addFolder('Mesh Motion').close();
	motionFolder.add(stopRotation, "stopMeshRotation").name('Stop Rotation');
	motionFolder.add(params, 'meshRotationX', -.01, .01, 0.0001).name('X-Axis Rotation');
	motionFolder.add(params, 'meshRotationY', -.01, .01, 0.0001).name('Y-Axis Rotation');
	motionFolder.add(params, 'meshRotationZ', -.01, .01, 0.0001).name('Z-Axis Rotation');

	const exportFolder = gui.addFolder('Mesh Export').close();
	exportFolder.add( exportParams, 'exportASCII' ).name( 'Export STL (ASCII)' );
	exportFolder.add( exportParams, 'exportBinary' ).name( 'Export STL (Binary)' );

	function exportASCII() {

		const result = exporter.parse( mesh );
		saveString( result, 'NeilPandyaGeneratedSurface-ASCII.stl' );

	};

	function exportBinary() {

		const result = exporter.parse( mesh, { binary: true } );
		saveArrayBuffer( result, 'NeilPandyaGeneratedSurface-Binary.stl' );

	};

	const link = document.createElement( 'a' );
	link.style.display = 'none';
	document.body.appendChild( link );

	function save( blob, filename ) {

		link.href = URL.createObjectURL( blob );
		link.download = filename;
		link.click();

	};

	function saveString( text, filename ) {

		save( new Blob( [ text ], { type: 'text/plain' } ), filename );

	};

	function saveArrayBuffer( buffer, filename ) {

		save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

	};

	window.addEventListener('resize', onWindowResize);
	onWindowResize();

	initRays();

};

function initRays() {

	const position = new THREE.Vector3();
	const quaternion = new THREE.Quaternion();
	const scale = new THREE.Vector3(1, 1, 1);
	const matrix = new THREE.Matrix4();

	for (let i = 0; i < MAX_RAYS * 2; i++) {

		position.randomDirection().multiplyScalar(4.00);
		matrix.compose(position, quaternion, scale);
		sphereInstance.setMatrixAt(i, matrix);

	};

};

function updateRays() {

	if (!mesh) return;

	_raycaster.firstHitOnly = params.firstHitOnly;
	const rayCount = params.count;

	let lineNum = 0;
	for (let i = 0; i < rayCount; i++) {

		// get the current ray origin
		sphereInstance.getMatrixAt(i * 2, _matrix);
		_matrix.decompose(_position, _quaternion, _scale);

		// rotate it about the origin
		const offset = 1e-4 * window.performance.now();
		_axis.set(
			Math.sin(i * 100 + offset),
			Math.cos(-i * 10 + offset),
			Math.sin(i * 1 + offset),
		).normalize();
		_position.applyAxisAngle(_axis, 0.00025);

		// update the position
		_scale.setScalar(0.01);
		_matrix.compose(_position, _quaternion, _scale);
		sphereInstance.setMatrixAt(i * 2, _matrix);

		// raycast
		_raycaster.ray.origin.copy(_position);
		_raycaster.ray.direction.copy(_position).multiplyScalar(-5).normalize();

		// update hits points and lines
		const hits = _raycaster.intersectObject(mesh);
		if (hits.length !== 0) {

			const hit = hits[0];
			const point = hit.point;
			_scale.setScalar(0.01);
			_matrix.compose(point, _quaternion, _scale);
			sphereInstance.setMatrixAt(i * 2 + 1, _matrix);

			lineSegments.geometry.attributes.position.setXYZ(lineNum++, _position.x, _position.y, _position.z);
			lineSegments.geometry.attributes.position.setXYZ(lineNum++, point.x, point.y, point.z);

		} else {

			sphereInstance.setMatrixAt(i * 2 + 1, _matrix);
			lineSegments.geometry.attributes.position.setXYZ(lineNum++, _position.x, _position.y, _position.z);
			lineSegments.geometry.attributes.position.setXYZ(lineNum++, 0, 0, 0);

		}

	sphereInstance.count = rayCount * 2;
	sphereInstance.instanceMatrix.needsUpdate = true;

	lineSegments.geometry.setDrawRange(0, lineNum);
	lineSegments.geometry.attributes.position.needsUpdate = true;

	}

}

function onWindowResize() {

	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize(window.innerWidth, window.innerHeight);

}

function animate() {

	requestAnimationFrame(animate);
	render();
	stats.update();

}

function render() {

	if (helper) {

		helper.visible = params.displayHelper;

	};

	const time = Date.now() * 0.0001;

	if (mesh) {

        mesh.rotation.x += params.meshRotationX;
		mesh.rotation.y += params.meshRotationY;
        mesh.rotation.z += params.meshRotationZ;
 		mesh.updateMatrixWorld();

	};

	pointLight1.position.x = Math.sin( time * 3 ) * -4.5;
	pointLight1.position.y = Math.cos( time * 3 ) * -4.5;
	pointLight1.position.z = 0;

	pointLight2.position.x = Math.sin( time * 3 ) * -4.5;
	pointLight2.position.y = 0;
	pointLight2.position.z = Math.cos( time * 3 ) * -4.5;

	pointLight3.position.x = Math.sin( time * 3 ) * 4.5;
	pointLight3.position.y = Math.cos( time * 3 ) * 4.5;
	pointLight3.position.z = 0;

	pointLight4.position.x = Math.sin( time * 3 ) * 4.5;
	pointLight4.position.y = 0;
	pointLight4.position.z = Math.cos( time * 3 ) * 4.5;

	updateRays();

	renderer.render(scene, camera);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

};
